import { ApiHrAbsenceTypeEnum } from "@/api/hr/types/absence/ApiHrAbsenceTypeEnum";

export interface HrCreateAbsenceRequest {
	employeeId: string;
	startDate: number;
	endDate: number;
	type: ApiHrAbsenceTypeEnum | null;
	comment?: string;
}

export class HrCreateAbsenceRequestHelper {
	static getEmpty(): HrCreateAbsenceRequest {
		return {
			employeeId: "",
			startDate: 0,
			endDate: 0,
			type: null,
			comment: "",
		};
	}
}
