import { convertToTimestamp } from "@/utils/dates";
import { ApiHrAbsenceTypeEnum } from "@/api/hr/types/absence/ApiHrAbsenceTypeEnum";
import { ApiHrAbsenceStateEnum } from "@/api/hr/types/absence/ApiHrAbsenceStateEnum";
import { ApiHrEmployeesItem } from "@/api/hr/types/apiHrEmployeesItem";
import { ApiHrAbsence } from "@/api/hr/types/absence/apiHrAbsence";
import { HrAbsenceColorMapper } from "@/types/hr/absence/hrAbsenceColorMapper";
import { i18n } from "@/plugins";
import { ApiHrAbsenceVacationApplication } from "@/api/hr/types/absence/apiHrAbsenceVacationApplication";

export interface HrAbsence {
	id: string;
	startDate: number;
	endDate: number;
	type: ApiHrAbsenceTypeEnum;
	state: ApiHrAbsenceStateEnum;
	comment: string;
	employee: ApiHrEmployeesItem;
	vacationApplication?: ApiHrAbsenceVacationApplication;
	
	employeeId: string;
	color: string;
	text: string;
	isApproved: boolean;
}

export class HrAbsenceHelper {
	static map(source: ApiHrAbsence): HrAbsence {
		return {
			...source,
			startDate: convertToTimestamp(source.dateStart) as number,
			endDate: convertToTimestamp(source.dateEnd) as number,
			employeeId: source.employee.id,
			color: HrAbsenceColorMapper.map(source),
			text: i18n.tc(`aliases.ganttAbsenceType.${source.type}`),
			isApproved: source.state === ApiHrAbsenceStateEnum.Approved,
		};
	}
}
