import { IsArray, IsString } from "class-validator";
import { ApiHrAbsenceTypeEnum } from "@/api/hr/types/absence/ApiHrAbsenceTypeEnum";

export default class HrAbsencesRouteQuery {
	@IsArray()
	@IsString({ each: true })
	departmentIds: string[];
	
	@IsArray()
	absenceTypes: ApiHrAbsenceTypeEnum[];
	
	@IsArray()
	@IsString({ each: true })
	employeeIds: string[];
	
	constructor(
		departmentIds: string[] = [],
		absenceTypes: ApiHrAbsenceTypeEnum[] = [],
		employeeIds: string[] = [],
	) {
		this.departmentIds = departmentIds;
		this.absenceTypes = absenceTypes;
		this.employeeIds = employeeIds;
	}
}
