import { ApiHrAbsence } from "@/api/hr/types/absence/apiHrAbsence";
import { AbsenceColorEnum } from "@/types/hr/absence/AbsenceColorEnum";
import { ApiHrAbsenceTypeEnum } from "@/api/hr/types/absence/ApiHrAbsenceTypeEnum";
import { ApiHrAbsenceStateEnum } from "@/api/hr/types/absence/ApiHrAbsenceStateEnum";

export class HrAbsenceColorMapper {
	static map(source: ApiHrAbsence): AbsenceColorEnum {
		switch (source.type) {
			case ApiHrAbsenceTypeEnum.PlannedVacation:
				return source.state === ApiHrAbsenceStateEnum.Approved ? AbsenceColorEnum.GREEN : AbsenceColorEnum.BLUE;
			case ApiHrAbsenceTypeEnum.Vacation:
				return AbsenceColorEnum.GREEN;
			case ApiHrAbsenceTypeEnum.Illness:
				return AbsenceColorEnum.ORANGE
			case ApiHrAbsenceTypeEnum.Study:
				return AbsenceColorEnum.YELLOW
			case ApiHrAbsenceTypeEnum.TraineeJob:
				return AbsenceColorEnum.PINK
			case ApiHrAbsenceTypeEnum.BusinessTrip:
				return AbsenceColorEnum.PURPLE
			case ApiHrAbsenceTypeEnum.Other:
				return AbsenceColorEnum.GREY
		}
	}
}
