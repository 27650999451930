import { formatDate } from "@/utils/dates";
import { isoDateFormat } from "@/utils/formats";
import { ApiHrAbsenceTypeEnum } from "@/api/hr/types/absence/ApiHrAbsenceTypeEnum";
import { HrCreateAbsenceRequest } from "@/types/hr/absence/hrCreateAbsenceRequest";

export interface ApiHrCreateAbsenceRequest {
	employeeId: string;
	from: string;
	to: string;
	type: ApiHrAbsenceTypeEnum;
	comment?: string;
}

export class ApiHrCreateAbsenceRequestHelper {
	static map(source: HrCreateAbsenceRequest): ApiHrCreateAbsenceRequest {
		return {
			employeeId: source.employeeId,
			from: formatDate(source.startDate, isoDateFormat) as string,
			to: formatDate(source.endDate, isoDateFormat) as string,
			type: source.type!,
			comment: source.comment,
		};
	}
}
