import SubscribersManager from "@/store/manager/subscribersManager";
import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/hr/modules/absences/types";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { RouteNames } from "@/router/hr/routes";
import router from "@/router/hr";
import routeTypes from "@/store/shared/route/types";
import { formatFullName } from "@/utils/formatting";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import { HrController } from "@/api/hr";
import { ApiHrGetEmployeesParameters } from "@/api/hr/types/apiHrGetEmployeesParameters";
import HrVacationApplicationsState
	from "@/store/hr/modules/vacationApplications/types/hrVacationApplicationsState";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import UserState from "@/store/hr/modules/user/types/userState";
import storeManager from "@/store/manager";
import { ApiHrRoleEnum } from "@/api/hr/types/ApiHrRoleEnum";
import { cloneDeep, groupBy } from "lodash";
import { CalendarController } from "@/api/calendar";
import { CalendarDateHelper } from "@/types/calendar/calendarDate";
import { getYear } from "date-fns";
import { HrAbsenceController } from "@/api/hr/absence";
import HrAbsencesRouteService from "@/store/hr/modules/absences/services/hrAbsencesRouteService";
import HrAbsencesRouteQuery from "@/store/hr/modules/absences/types/hrAbsencesRouteQuery";
import HrAbsencesState from "@/store/hr/modules/absences/types/hrAbsencesState";
import { HrAbsence, HrAbsenceHelper } from "@/types/hr/absence/hrAbsence";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import HrAbsencesFilter from "@/store/hr/modules/absences/types/hrAbsencesFilter";
import HrAbsencesMapper from "@/store/hr/modules/absences/mapper";
import { ApiHrCreateAbsenceRequestHelper } from "@/api/hr/types/absence/apiHrCreateAbsenceRequest";
import { HrCreateAbsenceRequestHelper } from "@/types/hr/absence/hrCreateAbsenceRequest";

const abortService = new AbortService();
const hrController = new HrController(abortService);
const hrAbsenceController = new HrAbsenceController(abortService);
const calendarController = new CalendarController(abortService);

const defaultRouteQuery = new HrAbsencesRouteQuery();

const routeService = new HrAbsencesRouteService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<HrAbsencesState>()).build();

const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["newAbsence"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new HrAbsencesState(
			new ListingModel<HrAbsence>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<string>({
				type: "",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			new HrAbsencesFilter(),
			routeMixin.state(),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<HrVacationApplicationsState>;

const getters = <GetterTree<HrVacationApplicationsState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: (state) => {
		const groups = groupBy(state.listing.items, "employeeId");
		const items = Object.values(groups).map(periods => {
			return { periods, id: periods[0].employee.id, fullName: formatFullName(periods[0].employee), employee: periods[0].employee };
		});
		
		return items;
	},
	[getterTypes.currentUser]: (state, getters, rootState) => {
		return resolveNestedState<UserState>(rootState, storeManager.hr.user.namespace).user;
	},
	[getterTypes.isAdmin]: (state, getters, rootState) => {
		const roles = resolveNestedState<UserState>(rootState, storeManager.hr.user.namespace).roles;
		
		return roles.includes(ApiHrRoleEnum.Administrator);
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<HrAbsencesState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	const namespace = storeManager.hr.absences.namespace;
	let state = resolveNestedState<HrAbsencesState>(rootState, namespace);
	
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_ABSENCE_TYPES):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_DEPARTMENT_IDS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_EMPLOYEE_IDS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			
			if(!state.isInitialized)
				return;
			
			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});
			
			break;
		}
	}
};

const actions = <ActionTree<HrAbsencesState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state, getters }) {
		await dispatch(actionTypes.initializeBase);
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		
		commit(mutationTypes.SET_YEAR, getYear(Date.now()));
		
		await Promise.all([
			dispatch(actionTypes.fetchDepartments),
			dispatch(actionTypes.fetchCalendar)
		]);
		
		await dispatch(actionTypes.processRoute);
		await dispatch(actionTypes.reconstituteRoute);
		
		if(getters.currentUser.department?.id && !state.filter.departmentIds.length)
			commit(mutationTypes.SET_FILTER_DEPARTMENT_IDS, [getters.currentUser.department.id]);
		
		await dispatch(actionTypes.updateListingItems);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			let items = await hrAbsenceController.getAbsences(HrAbsencesMapper.mapToGetApiHrGetAbsencesParameters(state));
			
			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => HrAbsenceHelper.map(x)));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.processRoute]({ rootState, commit, dispatch, state }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);
		
		let routeQuery = await routeService.resolveRouteQuery(rootState.route.query);
		
		// Если при открытии страницы уже стоят фильтры, нужно сразу загрузить соответствующие справочники
		if(routeQuery.employeeIds.length)
			await dispatch(actionTypes.fetchEmployees);
		
		commit(mutationTypes.SET_FILTER_ABSENCE_TYPES, routeQuery.absenceTypes);
		commit(mutationTypes.SET_FILTER_DEPARTMENT_IDS, routeQuery.departmentIds);
		commit(mutationTypes.SET_FILTER_EMPLOYEE_IDS, routeQuery.employeeIds);
		
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		if(state.isDestroyed)
			return;
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.push({
			name: RouteNames.ABSENCES,
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		if(state.isDestroyed)
			return;
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.replace({
			name: RouteNames.ABSENCES,
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetchDepartments]({ commit, state, dispatch }) {
		if(state.departments.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, true);
		
		try {
			const items = await hrController.getDepartments();
			
			commit(mutationTypes.SET_DEPARTMENTS, items);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, false);
		}
	},
	async [actionTypes.fetchEmployees]({ commit, state, dispatch }) {
		if(state.employees.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_EMPLOYEES_LOADING, true);
		
		try {
			const { employees } = await hrController.getEmployees({} as ApiHrGetEmployeesParameters);
			
			commit(mutationTypes.SET_EMPLOYEES, employees.map(x => ({ ...x, fullName: formatFullName(x) })));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_EMPLOYEES_LOADING, false);
		}
	},
	async [actionTypes.tryCreateAbsence]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		
		try {
			const absence = await hrAbsenceController.createAbsence(ApiHrCreateAbsenceRequestHelper.map(state.newAbsence));
			
			await dispatch(actionTypes.updateListingItems);
			
			alertService.addInfo(AlertKeys.ABSENCE_SUCCESS_ADDED);
			
			return true;
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			return false;
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.fetchCalendar]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_CALENDAR_LOADING, true);
		
		try {
			const [{ dates: currentYearDates }, { dates: nextYearDates }] = await Promise.all([
				calendarController.getWorkingDaysAndHolidays(state.year),
				calendarController.getWorkingDaysAndHolidays(state.year + 1)
			])
			
			commit(mutationTypes.SET_CALENDAR_DATES,
				[...currentYearDates.map(x => CalendarDateHelper.map(x)), ...nextYearDates.map(x => CalendarDateHelper.map(x))]);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_CALENDAR_LOADING, false);
		}
	}
};

const mutations = <MutationTree<HrAbsencesState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_FILTER_ABSENCE_TYPES](state, value) {
		state.filter.absenceTypes = value;
	},
	[mutationTypes.SET_FILTER_DEPARTMENT_IDS](state, value) {
		state.filter.departmentIds = value;
	},
	[mutationTypes.SET_FILTER_EMPLOYEE_IDS](state, value) {
		state.filter.employeeIds = value;
	},
	[mutationTypes.SET_FILTER](state, value) {
		state.filter = value;
	},
	[mutationTypes.SET_IS_DEPARTMENTS_LOADING](state, value) {
		state.isDepartmentsLoading = value;
	},
	[mutationTypes.SET_IS_EMPLOYEES_LOADING](state, value) {
		state.isEmployeesLoading = value;
	},
	[mutationTypes.SET_DEPARTMENTS](state, value) {
		state.departments = value;
	},
	[mutationTypes.SET_EMPLOYEES](state, value) {
		state.employees = value;
	},
	[mutationTypes.SET_CURRENT_OPENED_ABSENCE_ID](state, value) {
		state.currentOpenedAbsenceId = value;
	},
	[mutationTypes.RESET_NEW_ABSENCE](state, value) {
		state.newAbsence = HrCreateAbsenceRequestHelper.getEmpty();
	},
	[mutationTypes.SET_NEW_ABSENCE_TYPE](state, value) {
		state.newAbsence.type = value;
	},
	[mutationTypes.SET_NEW_ABSENCE_START_DATE](state, value) {
		state.newAbsence.startDate = value;
	},
	[mutationTypes.SET_NEW_ABSENCE_END_DATE](state, value) {
		state.newAbsence.endDate = value;
	},
	[mutationTypes.SET_NEW_ABSENCE_EMPLOYEE_ID](state, value) {
		state.newAbsence.employeeId = value;
	},
	[mutationTypes.SET_NEW_ABSENCE_COMMENT](state, value) {
		state.newAbsence.comment = value;
	},
	[mutationTypes.ADD_LISTING_ITEM](state, value) {
		state.listing.items.push(cloneDeep(value));
	},
	[mutationTypes.SET_IS_CALENDAR_LOADING](state, value) {
		state.isCalendarLoading = value;
	},
	[mutationTypes.SET_CALENDAR_DATES](state, value) {
		state.calendarDates = cloneDeep(value);
	},
	[mutationTypes.SET_YEAR](state, value) {
		state.year = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const hrAbsencesModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default hrAbsencesModule;
