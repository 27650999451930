import HrAbsencesState from "@/store/hr/modules/absences/types/hrAbsencesState";
import { ApiHrGetAbsencesParameters } from "@/api/hr/types/absence/apiHrGetAbsencesParameters";

export default class HrAbsencesMapper {
	static mapToGetApiHrGetAbsencesParameters(source: HrAbsencesState): ApiHrGetAbsencesParameters {
		return {
			years: [source.year, source.year + 1],
			absenceTypes: source.filter.absenceTypes,
			departmentIds: source.filter.departmentIds,
			employeeIds: source.filter.employeeIds
		};
	}
}
