import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls from "@/api/config/urls";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { ApiHrGetAbsencesParameters } from "@/api/hr/types/absence/apiHrGetAbsencesParameters";
import { ApiHrCreateAbsenceRequest } from "@/api/hr/types/absence/apiHrCreateAbsenceRequest";
import { ApiHrAbsence } from "@/api/hr/types/absence/apiHrAbsence";

export class HrAbsenceController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getAbsences = async (parameters: ApiHrGetAbsencesParameters = {}) => {
		return await this.client.get<ApiHrAbsence[]>(prepareUrl(urls.hr.absence.getAbsences, parameters));
	};
	
	createAbsence = async (payload: ApiHrCreateAbsenceRequest) => {
		return await this.client.post<ApiHrAbsence>(urls.hr.absence.createAbsence, payload);
	};
}
