import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { parseArrayParameter } from "@/utils/query";
import HrAbsencesRouteQuery from "@/store/hr/modules/absences/types/hrAbsencesRouteQuery";
import HrAbsencesState from "@/store/hr/modules/absences/types/hrAbsencesState";

export default class HrAbsencesRouteService {
	initialRouteQuery: HrAbsencesRouteQuery;
	defaultRouteQuery: HrAbsencesRouteQuery;
	
	constructor(defaultRouteQuery: HrAbsencesRouteQuery) {
		this.initialRouteQuery = defaultRouteQuery;
		this.setDefaultRoute(defaultRouteQuery);
	}

	setDefaultRoute(defaultRouteQuery: HrAbsencesRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}
	
	resolveRouteQueryDictionary(state: HrAbsencesState) {
		let query = new HrAbsencesRouteQuery(
			state.filter.departmentIds,
			state.filter.absenceTypes,
			state.filter.employeeIds,
		);
		
		return difference(this.initialRouteQuery, query);
	}
	
	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(HrAbsencesRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				departmentIds: parseArrayParameter(query.departmentIds),
				absenceTypes: parseArrayParameter(query.absenceTypes),
				employeeIds: parseArrayParameter(query.employeeIds),
			}, { enableImplicitConversion: true });

			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
